import { Cond } from 'Components/FormComponents';
import { Hooks } from 'Components/Hooks';

import {
  Backdrop,
  ViewConnected,
  StatusMessage,
  CallTimer,
  QualityWarning,

  ConfMeters,

  ConfModeDropdown,
  ConfModeLabel,

  ToggleHoldButton,
  ToggleMuteButton,
  ToggleHandRaisedButton,

  ToggleLockedButton,
  ToggleRecordingButton,
  ToggleMusicOnHoldButton,

  ToggleDialpadButton,
  SettingsIconButton,
  ChangeRoleButton,
  EndConferenceButton,

  FinishNameRecordingButton,
  StartConferenceButton,

  DialpadCollapsible,

  DisconnectButton,

  ViewConnecting,
  QualityMeterModal,
  MediaErrorModal,
  SettingsModal,
  ChangeRoleModal,
  EndConferenceModal,

  registerDrawLoop,
} from './UIComponents';

import { ConfDebugView } from './DebugComponents';

export default function ConfCallUI({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => hooks.run(ctrl));

  const ctx = {
    hooks,
    ctrl,
  };

  const root =
    <div class="conf-call-ui">
      <Backdrop ctx={ctx} />
      <ConfDebugView ctx={ctx} />
      <ViewConnected ctx={ctx}>
        <FinishNameRecordingButton ctx={ctx} />
        <StartConferenceButton ctx={ctx} />
        <StatusMessage ctx={ctx} />
        <CallTimer ctx={ctx} />
        <QualityWarning ctx={ctx} />

        <ConfMeters ctx={ctx} />

        <ConfModeDropdown ctx={ctx} />
        <ConfModeLabel ctx={ctx} />

        <div class="btn-panel">
          <div class="btn-toolbar-full">
            <ToggleHoldButton ctx={ctx} />
            <ToggleMuteButton ctx={ctx} />
            <ToggleHandRaisedButton ctx={ctx} />
          </div>

          <Cond test={!ctrl.hideHostControls}>
            <div class="btn-toolbar-full">
              <ToggleLockedButton ctx={ctx} />
              <ToggleRecordingButton ctx={ctx} />
              <ToggleMusicOnHoldButton ctx={ctx} />
            </div>

            <div class="btn-toolbar-full">
              <ToggleDialpadButton ctx={ctx} />
              <SettingsIconButton ctx={ctx} />
              <ChangeRoleButton ctx={ctx} />
              <EndConferenceButton ctx={ctx} />
            </div>
          </Cond>

          <Cond test={ctrl.hideHostControls}>
            <div class="btn-toolbar-full">
              <ToggleMusicOnHoldButton ctx={ctx} />
              <SettingsIconButton ctx={ctx} />
              <ChangeRoleButton ctx={ctx} hideWhenHost={false} />
            </div>
          </Cond>

          <DialpadCollapsible ctx={ctx} />
        </div>

        <DisconnectButton ctx={ctx} />
      </ViewConnected>

      <ViewConnecting ctx={ctx} />

      <QualityMeterModal ctx={ctx} />
      <MediaErrorModal ctx={ctx} />
      <SettingsModal ctx={ctx} />
      <ChangeRoleModal ctx={ctx} />
      <EndConferenceModal ctx={ctx} />
    </div>;

  registerDrawLoop(ctrl, root);

  return root;
}
