import 'shims';

import Loader from 'Loader';

import { ConfAppController } from './ConfAppController';
import ConfAppUI from './ConfAppUI';

import { isWebRtcSupported } from './BrowserSupport';
import { UnsupportedMessage } from './UIComponents';

import parseValidateUserID from './parseValidateUserID';

Loader.load()
  .then(siteConfig => {
    if (!isWebRtcSupported()) {
      document.body.appendChild(
        <UnsupportedMessage />
      );
      return;
    }

    const alwaysListenOnly = !!siteConfig.settings.WEBCALL_ALWAYS_LISTEN_ONLY;
    const hideHostControls = !!siteConfig.settings.WEBCALL_HIDE_HOST_CONTROLS;

    const {
      userIDEnabled,
      userIDRequired,
    } = parseValidateUserID(siteConfig.settings.WEBCALL_VALIDATE_USER_ID);

    const ctrl = new ConfAppController({
      appConfig: {
        wsSipURI: siteConfig.settings.WEBCALL_SIP_URI,
        toUriPrefix: siteConfig.settings.WEBCALL_TO_URI_PREFIX,
        toUriSuffix: siteConfig.settings.WEBCALL_TO_URI_SUFFIX,

        fetchLocationURL: siteConfig.settings.WEBCALL_LOCATION_URL || null,
        fetchRTCConfigurationURL: siteConfig.settings.WEBCALL_RTC_CONFIGURATION_URL || null,
        fetchIceServersURL: siteConfig.settings.ICE_SERVER_URL || null,
      },
      defaultConferenceID: siteConfig.settings.WEBCALL_CONFERENCE_ID,
      defaultFromName: siteConfig.settings.WEBCALL_DEFAULT_FROM_NAME,
      defaultEmail: siteConfig.settings.WEBCALL_DEFAULT_EMAIL,
      localStorageKey: 'webCall_conferenceConnectParams',
      alwaysListenOnly,
      enableTestApp: !siteConfig.settings.WEBCALL_DISABLE_TEST_APP && !alwaysListenOnly,
      enableSettings: !siteConfig.settings.WEBCALL_DISABLE_SETTINGS && !alwaysListenOnly,
      disableCommandButtons: siteConfig.settings.WEBCALL_DISABLE_COMMAND_BUTTONS,
      hideHostControls,
      userIDEnabled,
      userIDRequired,
      handleHash: true,
    });

    document.body.append(...(
      <>
        <div class="logo-container" />
        <ConfAppUI ctrl={ctrl} />
      </>
    ));

    ctrl.start();
  })
  .then(() => Loader.setLogo())
  .then(() => Loader.loadComplete())
  .catch(err => Loader.loadError(err));
