export default function parseValidateUserID(val) {
  let validateUserID = val;
  if (validateUserID === undefined) {
    validateUserID = 0;
  } else {
    validateUserID = parseInt(validateUserID, 10);
  }

  return {
    userIDEnabled: validateUserID !== 0,
    userIDRequired: [ 1, 3 ].includes(validateUserID),
  };
}
